import React, { ReactElement } from 'react';

// Components
import {
  Footer,
  Header,
  PostCard,
  WithScrollToTopButton,
  Wrapper,
} from '../components';

import {
  HomePosts,
  inner,
  PageTemplate,
  PostFeed,
  PostFeedRaise,
} from '../styles';

// Layouts
import { PageLayout, SEOContent } from 'layouts';

// Hooks
import { useAllPostsQuery } from 'hooks/PostsPage';
import { usePostsWithAdvertisements } from 'hooks';

// Types
import { Posts } from './index';
import { BasicTemplate } from '../templates';

const PostsPage = () => {
  const posts = useAllPostsQuery();
  const { postsWithAdvertisements } = usePostsWithAdvertisements(posts);

  return (
    <SEOContent css={HomePosts} title="All Articles">
      <Wrapper>
        <PageLayout css={PageTemplate}>
          <BasicTemplate templateTitle="All Articles">
            <section css={[PostFeed]}>
              <WithScrollToTopButton>
                <div css={inner}>
                  <div css={[PostFeed, PostFeedRaise]}>
                    {postsWithAdvertisements.map(
                      ({ data, fields }: Posts): ReactElement => (
                        <PostCard
                          isAdvertisement={!!data.advertisements}
                          key={data.post_id}
                          post={data}
                          postId={fields.postId}
                        />
                      )
                    )}
                  </div>
                </div>
              </WithScrollToTopButton>
            </section>
          </BasicTemplate>
        </PageLayout>
        <Footer />
      </Wrapper>
    </SEOContent>
  );
};

export default PostsPage;
