import { graphql, useStaticQuery } from 'gatsby';

export const useAllPostsQuery = () => {
  const {
    allAirtable: { nodes },
  } = useStaticQuery(graphql`
    {
      allAirtable(
        filter: { table: { eq: "Posts" } }
        sort: { fields: data___created_date, order: DESC }
      ) {
        nodes {
          fields {
            postId
          }
          data {
            attachments {
              localFiles {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            created_date(formatString: "MMM. Do, YYYY")
            excerpt
            time_to_read
            title
            post_id
            tags
            author {
              data {
                first_name
                full_name
                member_slug
              }
            }
            advertisements {
              data {
                external_url
                attachments {
                  localFiles {
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
                post_position
              }
            }
          }
        }
      }
    }
  `);
  return nodes;
};
